import { Grid, Stack } from "@mui/material";
import SimpleDataRow from "./SimpleDataRow";
import GMapsPolygon from "../../components/GMapsPolygon";
import LocalidadCard from "./LocalidadCard";

const DataCuartel = ({ data }) => {
  return (
    <LocalidadCard node={{ ...data, type: "cuartel" }}>
      <Grid container direction={{ xs: "column", sm: "row" }} spacing={2}>
        <Grid item xs={6}>
          <Grid container direction={{ xs: "column", sm: "row" }} spacing={2}>
            <SimpleDataRow label="Nombre" value={data.nombre} />
            <SimpleDataRow
              label="Cod. Cerificación"
              value={data.cod_certificacion}
            />
            <SimpleDataRow
              label="Superficie (m^2)"
              value={Math.floor(data.superficie)}
            />
          </Grid>
        </Grid>
        <Grid item direction={{ xs: "column", sm: "row" }} xs={6}>
          <GMapsPolygon polygon={data.polygon} />
        </Grid>
      </Grid>
    </LocalidadCard>
  );
};

export default DataCuartel;
