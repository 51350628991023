import { useState, useEffect, useReducer, useRef  } from "react";
import { getSensores, getSensorData, getPolygons, getNdviConfig, getIrrigationData } from "../../client/Meteorologia"
import { usePopup } from "../../contexts/PopupProvider";

import GMapsPolygons from "../../components/GMapsPolygons";
import CryptoJS from 'crypto-js';
import SensorList from './SensorList';
import IrrigationDataTable from './IrrigationDataTable';
import EmpresaSensorsChart from './EmpresaSensorsChart';
import moment from 'moment';

const MeteorologiaComponent = ({ empresaId })=>{
  const [sensors, setSensors] = useState([]);

  const [selectedSensors, setSelectedSensors] = useState([]);
  const [selectedSensorsData, setSelectedSensorsData] = useState({});
  const [polygons, setPolygons] = useState([]);
  const [theSmallestEpoch, setSmallestEpoch] = useState(-1);
  const [theBiggestEpoch, setBiggestEpoch] = useState(-1);
  const [ndviTileUrl, setNdviTileUrl] = useState(null);
  const [irrigationData, setIrrigationData] = useState([]);

  const popup = usePopup()

  const sensorDataCache = useRef({});

  function getSensorById(id_sensor) 
  {
    const s = sensors.find(sensor => sensor.id_sensor === id_sensor);
    return s;
  }
  const formatXAxis = (tickItem) => {
    const e = theSmallestEpoch + (tickItem * (theBiggestEpoch - theSmallestEpoch)) / 1000.0;
    const momentObject = moment(e * 1000);
    const formattedDateTime = momentObject.format('YYYY-MM-DD'); //  HH:mm:ss
    return formattedDateTime;
  };

  function fixSensorData(finalData)
  {
    const startTime = performance.now();
    const ids = Object.getOwnPropertyNames(finalData);
    let smallestEpoch = -1;
    let biggestEpoch = -1;
    let initialPoints = 0, finalPoints = 0;

    ids.forEach((id) => {
      const data = finalData[id];

      initialPoints += data.length;

      data.forEach((d) => {
        if (smallestEpoch == -1 || smallestEpoch > d.epochTimeInSeconds) smallestEpoch = d.epochTimeInSeconds;
        if (biggestEpoch == -1 || biggestEpoch < d.epochTimeInSeconds) biggestEpoch = d.epochTimeInSeconds;
      });
    });

    const m = 1000 / (biggestEpoch - smallestEpoch);

    ids.forEach((id) => { 
      const data0 = finalData[id];

      const data1 = data0.map(d => {
        const x = Math.trunc(m * (d.epochTimeInSeconds - smallestEpoch));
        return {
          valor: d.valor, fecha: d.fecha, epochTimeInSeconds: d.epochTimeInSeconds, x: x
        };
      });

      const grouped = data1.reduce((acc, item) => {
        if (!acc[item.x]) {
          acc[item.x] = { sum: item.valor, count: 1 }; // Initialize for new `x` value
        } else {
          acc[item.x].sum += item.valor; // Add to the sum
          acc[item.x].count += 1;    // Increment the count
        }
        return acc;
      }, {});

      const result = Object.keys(grouped).map((x) => ({
        x: parseInt(x, 10), // Convert key back to number
        valor: grouped[x].sum / grouped[x].count, // Calculate average
      }));

      finalPoints += result.length;
      finalData[id] = result;
    });
    const elapsedMsecs = performance.now() - startTime;
    console.log("points count: " + initialPoints + " -> " + finalPoints + " in " + elapsedMsecs + " msecs");

    setSmallestEpoch(smallestEpoch);
    setBiggestEpoch(biggestEpoch);
  }

  useEffect( () => {
    console.log("selectedSensors="+selectedSensors)

    let finalData = {};

    const maybeSetFinalSensorData = (id) => {
      if (id == selectedSensors.at(-1)) { // soy el último sensor?
        fixSensorData(finalData);
        setSelectedSensorsData(finalData);
        console.log("selectedSensorsData has " + Object.getOwnPropertyNames(finalData).length + " items");
      }
    };

    selectedSensors.forEach((id) => {
      if (sensorDataCache.current[id]) {
        console.log("Using cached data for sensor:", id);
        finalData[id] = sensorDataCache.current[id];
        maybeSetFinalSensorData(id);
        return;
      }
      getSensorData({ id_sensor: id })
        .then((data) => {

          data = data.map(d => { 
            const dateObject = new Date(d.fecha);
            const epochTimeInSeconds = Math.floor(dateObject.getTime() / 1000);

            return {
              valor: d.valor, fecha: d.fecha, epochTimeInSeconds:epochTimeInSeconds
            };
          });

          sensorDataCache.current[id] = data;
          finalData[id] = data;

          maybeSetFinalSensorData(id);
        })
        .catch(() => {
          popup.error("No se puede obtener data para el sensor");
        });
    });
  }, [selectedSensors]);

  useEffect(()=>{
    if(empresaId){
      console.log("empresaId=" + empresaId);

      getSensores({empresa_id:empresaId})
      .then((sensores)=>{
        console.log("sensores=", sensores);
        setSensors(sensores);
      })
      .catch(()=>{
        popup.error("No se obtener el listado de sensores");
        setSensors([]);
      });

      getPolygons({empresa_id:empresaId})
      .then((data)=>{
        // {"localidad_id":5,"nombre":"AG.WILSON YAÑEZ_SECTOR_PALTOS HASS","superficie":13797.355983851478,"polygon_size":776,"polygon":....
        console.log("getPolygons=", JSON.stringify(data));
        //const polys = data.map((p) => { return p.polygon; })
        setPolygons(data);
      })
      .catch(()=>{
        popup.error("No se obtener el listado de polígonos");
        setPolygons([]);
      });

      getNdviConfig({empresa_id:empresaId})
      .then((data)=>{
        console.log("getNdviConfig=", JSON.stringify(data[0]));
        setNdviTileUrl(data[0].tileurl);
        console.log("tileurl='"+data[0].tileurl+"'")
      })
      .catch(()=>{
        popup.error("No se obtener la configuración NDVI");
        setNdviTileUrl(null);
      });

      getIrrigationData({empresa_id:empresaId})
      .then((data)=>{
        console.log("getIrrigationData=", JSON.stringify(data));
        setIrrigationData(data);
      })
      .catch(()=>{
        popup.error("No se obtener recomendaciones de irrigación");
        setIrrigationData([]);
      });
    }
  },[empresaId])
  
  const handleToggle = (sensorId) => {
    const currentIndex = selectedSensors.indexOf(sensorId);
    const newSelectedSensors = [...selectedSensors];

    if (currentIndex === -1) {
      // Add to selected sensors
      newSelectedSensors.push(sensorId);
    } else { 
      // Remove from selected sensors
      newSelectedSensors.splice(currentIndex, 1);
    }

    setSelectedSensors(newSelectedSensors);
  };

  return (
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column',
          height: '100vh'
        }}>
          {/* Two Column Layout */}
          <div style={{ 
            display: 'flex',
            flex: 1
          }}>
            <div style={{
              flex: 1,
              paddingRight: '2px',
              backgroundColor: '#f5f5f5'
            }}>
              <GMapsPolygons key={CryptoJS.SHA256(JSON.stringify(polygons)).toString(CryptoJS.enc.Hex)} 
                polygons={polygons} 
                tileUrl={ndviTileUrl}
              />
            </div>
            
            <div style={{
              width: '310px',
              padding: '0px',
              backgroundColor: '#fafafa',
              borderLeft: '1px solid #e5e5e5'
            }}>
              {
                irrigationData.map((d) => {
                  return (<IrrigationDataTable key={d.nombre.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase()} data={d.recomendacion} title={d.nombre}/>);
                })
              }
            </div>

            
          </div>

          
          
          {/* Two Column Layout */}
          <div style={{ 
            display: 'flex',
            flex: 1
          }}>
            <div style={{
              width: '300px',
              padding: '0px',
              backgroundColor: '#fafafa',
              borderLeft: '1px solid #e5e5e5'
            }}>
              <SensorList sensors={sensors} handleToggle={handleToggle} selectedSensors={selectedSensors} />
            </div>

            <div style={{
              flex: 1,
              paddingLeft: '3px',
              backgroundColor: '#f5f5f5'
            }}>
              <EmpresaSensorsChart selectedSensorsData={selectedSensorsData} tickFormatter={formatXAxis} getSensorById={getSensorById} />
            </div>
          </div>
        </div>
  );
}

export default MeteorologiaComponent;