import { useEffect, useId, useRef} from 'react';
import GMapsWrapper from './GMapsWrapper';
import GMapsBaseBox from './GMapsBaseBox';
import { Box, Stack, useTheme } from '@mui/material';
import { useGPS } from '../contexts/GPSProvider';
import { Icon } from '@mdi/react';
import { mdiAccount, mdiMapMarkerOff } from '@mdi/js';
import { renderToString } from "react-dom/server"
import createMdiGlyph from "../utils/createMdiGlyph";
import ClipMapType from './GMapsClipMapType';

/**
 * @param {object} props
 * @param { [number,number][] } props.polygon
 * @param { string } props.color
 * @param {import('@mui/material/Box').BoxProps} props.props
 */
const GMapsPolygonsContent = ({ polygons = [], color="#FFFF00", tileUrl, ...props }) => {
  const maps = window?.google?.maps
  const { lngLatPosition } = useGPS()
  const ref = useRef(null)
  const mapId = useId()

  useEffect(() => {
    const refresh = async () =>{
      if (!polygons) { console.warn("No hay poligonos"); return; } 
      //if (!Array.isArray(polygons)) { console.warn("polygons no es un array"); return; }
      if (polygons.length === 0) { console.warn("No hay polígonos"); return; } 
      //if (!Array.isArray(polygons[0])) { console.warn("El polygon no es un array") ; return }
      //if (!Array.isArray(polygons[0][0])) { console.warn("No hay polígonos 2"); return; } 
      if (!maps) { console.warn("No hay map"); return; }

      const allLng = [], allLat = [];

      polygons.forEach((pdata) => {
        if (pdata.polygon == null) return;

        pdata.polygon.forEach((p) => {
          allLng.push(p[0]);
          allLat.push(p[1]);
        });
      });

      const center =  {
        lng: allLng.reduce((sum, value) => sum + value, 0) / allLng.length,
        lat: allLat.reduce((sum, value) => sum + value, 0) / allLat.length,
      };

      console.log("center", center);

      const map = new maps.Map(ref.current, {
        center,
        mapId,
        mapTypeId: maps.MapTypeId.SATELLITE,
        mapTypeControl: false,
        streetViewControl: false,
        controlSize:24,
        tilt: 0
      });

      let north = -9999, south = 9999, east = -9999, west = 9999;

      

      polygons.forEach((pdata) => {
        const polygon = pdata.polygon;
        if (polygon == null) return;
        
        const polygonLngLat = polygon.map(([lng,lat])=>({lng,lat}))

        // Construct the polygon.
        const mapPolygon = new maps.Polygon({
          paths: polygonLngLat,
          strokeColor: color,
          strokeOpacity: 1,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.2,
        });
        
        mapPolygon.setMap(map);

        // {"localidad_id":5,"nombre":"AG.WILSON YAÑEZ_SECTOR_PALTOS HASS","superficie":13797.355983851478,"polygon_size":776,"polygon":....
        const contentString =
          `
            <div id="content">
              <h1 id="firstHeading" class="firstHeading">${pdata.nombre}</h1>
              <div id="bodyContent">
                <p>Superficie: ${pdata.superficie}
              </div>
            </div>
          `;
        const infowindow = new maps.InfoWindow({
          headerDisabled: true,
          content: contentString
        });
        
        mapPolygon.addListener('mouseover', function(event) {
          infowindow.setPosition(event.latLng); // Position the InfoWindow at the mouse location
          infowindow.open(map);
        });
        mapPolygon.addListener('mouseout', function() {
          infowindow.close();
        });

        const lats = polygonLngLat.map(p => p.lat);
        const lngs = polygonLngLat.map(p => p.lng);
        
        north = Math.max(north, ...lats);
        south = Math.min(south, ...lats);
        east = Math.max(east, ...lngs);
        west = Math.min(west, ...lngs);
      });

      map.fitBounds({ north, south, east, west }, 16);

      const pin = new window.google.maps.marker.PinElement({
        glyph: createMdiGlyph({path: mdiAccount}),
        glyphColor: "white",
        scale:1.5
      });
    
      if(lngLatPosition && lngLatPosition.lat && lngLatPosition.lng){
        const marker = new maps.marker.AdvancedMarkerView({
          position: lngLatPosition,
          map,
          title: "Usted",
          content: pin.element,
        })
      }

      const x = new ClipMapType(polygons, map, tileUrl);
      map.overlayMapTypes.insertAt(0, x);

    }
 
    refresh();
  },[polygons, color, maps]);

  return <GMapsBaseBox {...props} ref={ref}/>;
};

const GMapsPolygons = GMapsWrapper(GMapsPolygonsContent)    

export default GMapsPolygons;
