import { useEffect, useState } from "react";
import TreeViewDrawer from "./TreeViewDrawer";
import MainContainer from "../../components/MainContainer";
import BaseBox from "../../components/BaseBox";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import NavDrawer from "../../components/Drawer/NavDrawer";
import NodeContent from "./NodeContent";
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import { Icon } from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import { isMobileScreen } from "../../utils/breakpoints";

const Localidades = () => {
  const { selectedNode } = useLocalidades();
  const [isMobile, setIsMobile] = useState(isMobileScreen);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen());
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <BaseBox>
      <NavDrawer />
      {!isMobile && <TreeViewDrawer />}
      <MainContainer>
        {isMobile && (
          <Accordion style={{ marginBottom: 20 }}>
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <Icon path={mdiChevronDown} size={1} />
                </IconButton>
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Detalle
            </AccordionSummary>
            <AccordionDetails>
              <TreeViewDrawer />
            </AccordionDetails>
          </Accordion>
        )}

        {selectedNode ? (
          <NodeContent />
        ) : (
          <List>
            <ListItem>
              <ListItemButton disabled>
                <i>Seleccione un nodo para ver detalles</i>
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </MainContainer>
    </BaseBox>
  );
};

export default Localidades;
