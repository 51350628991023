import { useState } from 'react'
import { Card } from '@mui/material'
import EmpresaAutocomplete from './EmpresaAutocomplete'
import { useLocalidades } from '../contexts/LocalidadesProvider'
import { useUsuario } from "../contexts/UsuarioProvider"

const MisEmpresaSearchPanel = ({ onSelect })=>{
  const [ empresaSelected, setEmpresaSelected ] = useState(null)
  const { empresas } = useLocalidades()
  const { usuario } = useUsuario(); // apellidos:"Godoy Perez" nombres:"Nicole SYSADMIN" rut:"1746520311" tipo:"SYSADMIN" usuario_id:45

  // const isRol = rol => empresas.state.find(e => e.empresa_id === empresaId?.empresa_id)?.roles.includes(rol)

  // const isJefe = isRol("JEFE")
  // const isAdmin = isRol("ADMIN")
  // const isSysAdmin = usuario?.tipo === "SYSADMIN"

  // ROLES: ADMIN JEFE SUPERVISOR
  // TIPOS USUARIO: SYSADMIN PROPIETARIO INVITADO

  // comuna_id: "04303"
  // direccion: "Balmaceda 54-Monte Patria-pc El Rincon, Rapel-Monte Patria"
  // empresa_id: 5
  // localidad: "Monte Patria"
  // nombre: "WILSON YAÑEZ"
  // propietario_email: "agrikolakorona13@gmail.com"
  // propietario_id: 67
  // representante_legal: "WILSON YAÑEZ"
  // roles: Array(2) 0:"ADMIN" 1:"JEFE"

  const misEmpresas = empresas.state.filter((e) => {
      if (usuario.tipo === "SYSADMIN") return true;

      //if (usuario.tipo === "PROPIETARIO") {
      if (usuario.usuario_id == e.propietario_id) return true;
      //}
      if (e.roles.includes("ADMIN")) return true;

      return false;
    });
  // misEmpresas.length = 1;

  return (
      misEmpresas.length > 0 && (
      <Card sx={{mb:1,p:1}}>
        <EmpresaAutocomplete
          label="Seleccione Empresa"
          empresas={misEmpresas}
          value={empresaSelected}
          onChange={(e,v)=>{
            setEmpresaSelected(v);
            onSelect(v?.empresa_id)
          }}
        />
      </Card> )
  )
}
export default MisEmpresaSearchPanel