import { Autocomplete, ListItemButton, Stack, TextField, Box } from "@mui/material";
import { mdiCalendar, mdiCheckboxMarked, mdiClock, mdiDecimal, mdiFormDropdown, mdiNumeric, mdiRadioboxMarked, mdiText, mdiTriangleDown } from "@mdi/js"
import { Icon } from "@mdi/react";

const dt = (text, type, icon)=>({text, type, icon});

const datatypes = [
  dt( "ENTERO",   "int",    mdiNumeric),
  dt( "DECIMAL",  "decimal",  mdiDecimal),
  dt( "TEXTO",    "text",   mdiText),
  dt( "SI/NO",    "boolean",mdiCheckboxMarked),
  dt( "OPCIONES", "enum",   mdiFormDropdown),
  dt( "HORA",     "time",   mdiClock),
  dt( "FECHA",    "date",   mdiCalendar),
]

const DataTypeSelector = ({ value, onChange, disabled }) =>{
  const innerValue = datatypes.find(dt=>dt.type === value) ?? null
  return <Stack spacing={2}>
    <Autocomplete
      disableClearable
      options={datatypes}
      getOptionKey={ option => option.type }
      getOptionLabel={ option => option.text}
      value={innerValue}
      onChange={( _, value) => { onChange(value.type)}}
      renderOption={(props,option,{selected})=>{
        return (
          <ListItemButton {...props} selected={selected}>
            <Box sx={{mr:1, display:"inline-flex"}}>
              <Icon path={option.icon} size={1}/>
            </Box>
            {option.text}
          </ListItemButton>
        )
      }}
      renderInput={props=>(
        <TextField
          {...props}
          InputProps={{
            ...props.InputProps,
            startAdornment: value && <Icon path={innerValue?.icon} size={1}/>,
          }}
          size="small"
          style={{ width: 180}}
        />
        )}
      disabled={disabled}
    />
  </Stack>

}

export default DataTypeSelector;