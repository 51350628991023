import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ListItemIcon } from '@mui/material';
import { Icon } from '@mdi/react';
import { useLocation } from 'react-router-dom';
import { icons }  from "../../utils/icons"
import { useLocalidades } from '../../contexts/LocalidadesProvider';
import { useUsuario } from '../../contexts/UsuarioProvider';

// Roles en la empresa
const ADMIN = 'ADMIN'
const JEFE = 'JEFE'
const SUPERVISOR = 'SUPERVISOR'

// Tipo de usuario
const PROPIETARIO = 'PROPIETARIO'
const SYSADMIN = 'SYSADMIN'

/**
 * @typedef { (options:{empresas:any[],usuario:any})=>boolean } MenuItemShowFunction
 */

/** @type { (roles:string[])=>MenuItemShowFunction } */
export const checkRol = (roles) => ({empresas,usuario}) => usuario.tipo !== 'SYSADMIN' && empresas.some(e => e.roles.some(r => roles.includes(r)));

/** @type { (tipos:string[])=>MenuItemShowFunction } */
export const checkTipo = (tipos) => ({usuario}) => tipos.includes(usuario?.tipo)

/** @type { (...checks:MenuItemShowFunction[]) => MenuItemShowFunction} */
export const checkSome = (...checks) => (options) => checks.some(fn=>fn(options))

/**
 * @typedef {object} MenuItem
 * @property {string} text
 * @property {string} href
 * @property {string} icon
 * @property { MenuItemShowFunction | boolean } show
 */

/** @type {Array<MenuItem>} */
const items = [
  {
    text: "Empresas",
    href: "/empresas",
    icon: icons.localidades,
    show: checkSome(
      checkRol([ADMIN, JEFE, SUPERVISOR]),
      checkTipo([PROPIETARIO, SYSADMIN])
    )
  },
  {
    text: "Meteorología",
    href: "/meteorologia",
    icon: icons.meteorologia,
    show: checkSome(
      checkRol([ADMIN]),
      checkTipo([SYSADMIN])
    )
  },
  {
    text: "Cultivos y variedades",
    href: "/cultivos-variedades",
    icon: icons.cultivo,
    show: checkSome(
      checkRol([ADMIN, JEFE]),
      checkTipo([PROPIETARIO, SYSADMIN])
    )
  },
  {
    text: "Jefes de campo",
    href: "/jefes",
    icon: icons.jefe,
    show: checkSome(
      checkRol([ADMIN]),
      checkTipo([PROPIETARIO, SYSADMIN])
    )
  },
  {
    text: "Supervisores",
    href: "/supervisores",
    icon: icons.supervisor,
    show: checkSome(
      checkRol([JEFE]), // <- era el único
      checkTipo([PROPIETARIO, SYSADMIN])
    )
  },
  {
    text: "Categorías de labores",
    href: "/categorias",
    icon: icons.categoria,
    show: checkSome(
      checkRol([JEFE]), // <- era el único
      checkTipo([PROPIETARIO, SYSADMIN])
    )
  },
  {
    text: "Planillas de labores",
    href: "/planillas",
    icon: icons.planilla,
    show: checkSome(
      checkRol([JEFE]), // <- era el único
      checkTipo([PROPIETARIO, SYSADMIN])
    )
  },
  {
    text: "Reportes",
    href: "/reportes",
    icon: icons.reporte,
    show: checkSome(
      checkRol([JEFE, SUPERVISOR]), // <- era el único
      checkTipo([PROPIETARIO, SYSADMIN])
    )
  },
  {
    text: "Generar reporte",
    href: "/generar-reporte",
    icon: icons.generarReporte,
    show: checkSome(
      checkRol([SUPERVISOR]), // <- era el único
      checkTipo([PROPIETARIO, SYSADMIN])
    )  }
]

function DrawerMenu() {
  const location = useLocation()
  const { empresas } = useLocalidades()
  const { usuario } = useUsuario()

  const filteredItems = items.filter(item=>{
    if(typeof item.show === 'undefined') return true;
    if(typeof item.show === 'boolean') return item.show;
    return item.show({
      empresas: empresas.state,
      usuario
    })
  })

  return (
    <List component="nav" sx={{flex:1}}>
      {
        filteredItems.map((item,i)=>(
          <ListItem
            key={i}
            disablePadding
          >
            <ListItemButton
              selected={ item.href === location.pathname }
              component="a"
              href={item.href}
            >
              <ListItemIcon>
                <Icon path={item.icon} size={1} />
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))
      }
    </List>
  );
}
export default DrawerMenu;