import { useState, useEffect } from "react";
import { Stack,TextField, Button, Grid, CardContent, Box } from "@mui/material";
import { mdiContentSave } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import { putCampo, deleteCampoById } from "../../client/Campo";
import KmlFileInput from "../../components/KmlFileInput";
import { calculateSphericPolygonArea } from "../../utils/SphereGeometry";
import GMapsPolygon from "../../components/GMapsPolygon";
import LocalidadCard from "./LocalidadCard";
import { usePopup } from "../../contexts/PopupProvider";
import DeleteDialogButton from "../../components/DeleteDialogButton";

const FormCampo = ({ data }) => {
  const {campos} = useLocalidades();
  const [nombre, setNombre] = useState(data.nombre)
  const [superficie, setSuperficie] = useState(data.superficie)
  const [polygon, setPolygon] = useState(data.polygon)
  const popup = usePopup()

  const handleSaveClick = () => {
    putCampo( data.campo_id, {
      nombre,
      superficie,
      polygon,
    })
      .then(data=>{
        campos.upsert(data);
        popup.success(`Campo "${nombre}" guardado correctamente`)
      })
      .catch(err=>{
        popup.error("No se pudo guardar el campo")
      })
  }

  useEffect(()=>{
    setSuperficie(calculateSphericPolygonArea(polygon))
  },[polygon])

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const handleDelete = () => {
    deleteCampoById(data.campo_id)
    .then(()=>{
      campos.drop({campo_id: data.campo_id})
      popup.success(`El campo "${nombre}" se ha eliminado correctamente.`);
      setDeleteDialogOpen(false)
    })
    .catch(err=>{
      popup.error("No se pudo eliminar el campo")
      setDeleteDialogOpen(false)
    })
  }

  return <>
    <LocalidadCard node={{ ...data, type:"campo" }}>
      <Grid container direction={{ xs: "column", sm: "row" }}  spacing={2}>
        <Grid item xs={6}>
          <CardContent>
            <Stack spacing={2}>
              <TextField fullWidth label="Nombre" value={nombre} onChange={(e)=>setNombre(e.target.value)}/>
              <KmlFileInput value={polygon} onChange={(v)=>setPolygon(v)}/>
              <TextField fullWidth label="Superficie (m^2)" value={Math.round(superficie)} disabled/>
            </Stack>
          </CardContent>
        </Grid>
        <Grid item direction={{ xs: "column", sm: "row" }}  xs={6}>
          <CardContent>
            <GMapsPolygon polygon={polygon}/>
          </CardContent>
        </Grid>
      </Grid>
    </LocalidadCard>
    <Stack direction="row" spacing={2} justifyContent="end" sx={{mt:2}}>
      <Button onClick={handleSaveClick} startIcon={<Icon path={mdiContentSave} size={1}/>} variant="contained">
        Guardar
      </Button>
      <Box flexGrow={1}/>
      <DeleteDialogButton
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        onConfirm={handleDelete}
        title="Eliminar Campo"
        buttonText="Eliminar Campo"
        question={<>
          ¿Estás seguro que deseas eliminar el campo <b>"{nombre}"</b>?
          Una vez eliminado no se podrá recuperar.
        </>}
      />
    </Stack>
  </>;
}

export default FormCampo;