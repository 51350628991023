import { useState } from "react";
import Button from "@mui/material/Button";
import { Icon } from "@mdi/react";
import { mdiFileMarkerOutline } from "@mdi/js";
import { usePopup } from "../contexts/PopupProvider";

function KmlFileInput({ onChange, value }) {
  const [file, setFile] = useState(null);
  const [polygon, setPolygon] = useState(value ?? []);
  const popup = usePopup();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const polygon = parseKml(content, file.name);
        if (polygon) {
          setPolygon(polygon);
          setFile(file);
        }
      };
      reader.readAsText(file);
    }
  };

  const parseKml = (kmlContent, filename) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(kmlContent, "text/xml");

    const polygons = xmlDoc.getElementsByTagName("Polygon");
    if (polygons.length === 0) {
      popup.error(
        `No se han encontrado polígonos en el archivo "${filename}". Asegúrese de que el archivo contenga al menos un polígono y este esté cerrado.`
      );
    }

    for (let i = 0; i < polygons.length; i++) {
      const thisPolygon = polygons[i];
      const coordinatesNode =
        thisPolygon.getElementsByTagName("coordinates")[0];
      if (!coordinatesNode) continue;
      const coordinatesString = coordinatesNode.textContent.trim();
      const coordinatesArray = coordinatesString
        .split(" ")
        .map((coordString) => {
          const [lon, lat, altitude] = coordString.split(",").map(Number);
          return [lon, lat];
        });

      const filteredData = coordinatesArray.filter(
        ([lon, lat]) => lon !== 0 && lat !== undefined
      );
      // Call the onChange callback with the extracted coordinates array
      onChange && onChange(filteredData);
      return setPolygon(filteredData);
    }

    popup.error(
      `No se ha encontrado un polígono válido en el archivo "${filename}". Asegúrese de que el archivo contenga al menos un polígono y este esté cerrado.`
    );
  };

  return (
    <Button
      variant="outlined"
      sx={{ height: 64 }}
      component="label"
      startIcon={<Icon path={mdiFileMarkerOutline} size={1} />}
      fullWidth
    >
      {file ? file.name : "Subir archivo KML"}
      <input type="file" hidden accept=".kml" onChange={handleFileChange} />
    </Button>
  );
}

export default KmlFileInput;
