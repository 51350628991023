import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

// Helper function to format strings as valid keys
const formatStringAsKey = (str) => {
  return str
    .replace(/[^a-zA-Z0-9]/g, '_') // Replace special characters with underscores
    .toLowerCase(); // Convert to lowercase
};

const IrrigationDataTable = ({ data, title }) => {
  if (data == null) {
    data = [ { 'Mensaje': 'No hay datos para mostrar' }]
  }
  // Dynamically get headers from the first object in the data array
  const headers = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <div>
      {/* Header with Title */}
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      {/* Compact Table with No Padding and No Space Between Columns */}
      <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto' }}>
        <Table
          size="small"
          aria-label="irrigation data table"
          sx={{ borderCollapse: 'collapse', width: '100%' }} // Remove space between columns
        >
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={formatStringAsKey(header)}
                  align="right"
                  sx={{
                    fontWeight: 'bold',
                    backgroundColor: '#f5f5f5',
                    padding: '0', // Remove padding
                    borderRight: '1px solid #e0e0e0', // Add border between columns
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                {headers.map((header) => (
                  <TableCell
                    key={formatStringAsKey(header)}
                    align="right"
                    sx={{
                      padding: '0', // Remove padding
                      borderRight: '1px solid #e0e0e0', // Add border between columns
                    }}
                  >
                    {row[header]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default IrrigationDataTable;