import {
  mdiAccount,
  mdiAccountEye,
  mdiAccountHardHat,
  mdiAccountOutline,
  mdiDomain,
  mdiFileEdit,
  mdiFileTable,
  mdiFileTree,
  mdiLandPlots,
  mdiLeaf,
  mdiMap,
  mdiShieldAccount,
  mdiSprout,
  mdiSquareOutline,
  mdiTableCog,
  mdiTag,
  mdiMeteor,
} from "@mdi/js"

export const icons = Object.freeze({
  empresa: mdiDomain,
  meteorologia: mdiMeteor,
  campo: mdiMap,
  sector: mdiLandPlots,
  cuartel: mdiSquareOutline,
  cultivo: mdiSprout,
  variedad: mdiLeaf,
  localidades: mdiFileTree,
  admin: mdiShieldAccount,
  jefe: mdiAccountHardHat,
  supervisor: mdiAccountEye,
  categoria: mdiTag,
  planilla: mdiTableCog,
  reporte: mdiFileTable,
  generarReporte: mdiFileEdit,
  propietario: mdiAccount,
  invitado: mdiAccountOutline,
})