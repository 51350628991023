import BaseBox from "../../components/BaseBox";
import NavDrawer from "../../components/Drawer/NavDrawer";
import { PlanillasProvider, usePlanillas } from "./PlanillasContext";
import PlanillasSelectPanel from "./PlanillaSelectPanel";
import PlanillasDetail from "./PlanillaDetail";
import { SupervisoresProvider } from "../../contexts/SupervisoresProvider";
import { isMobileScreen } from "../../utils/breakpoints";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Icon } from "@mdi/react";
import { mdiChevronDown } from "@mdi/js";
import MainContainer from "../../components/MainContainer";

const Content = () => {
  const { empresaSelected } = usePlanillas();
  const [isMobile, setIsMobile] = useState(isMobileScreen);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileScreen());
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <SupervisoresProvider empresaId={empresaSelected?.empresa_id}>
      <NavDrawer />
      {!isMobile && <PlanillasSelectPanel />}
      <MainContainer>
        {isMobile && (
          <Accordion style={{ marginBottom: 20 }}>
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <Icon path={mdiChevronDown} size={1} />
                </IconButton>
              }
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Detalle
            </AccordionSummary>
            <AccordionDetails>
              <PlanillasSelectPanel />
            </AccordionDetails>
          </Accordion>
        )}
        <PlanillasDetail />
      </MainContainer>
    </SupervisoresProvider>
  );
};

const Planillas = () => {
  return (
    <BaseBox>
      <PlanillasProvider>
        <Content />
      </PlanillasProvider>
    </BaseBox>
  );
};

export default Planillas;
