import { useState, useEffect } from "react";
import MisEmpresaSearchPanel from "../../components/MisEmpresaSearchPanel";
import MainContainer from "../../components/MainContainer";
import BaseBox from "../../components/BaseBox";
import NavDrawer from "../../components/Drawer/NavDrawer";
import { usePopup } from "../../contexts/PopupProvider";
import MeteorologiaComponent from "./MeteorologiaComponent";
import Alert from '@mui/material/Alert';

const MeteorologiaComponentAdmin = ()=>{
  const [empresaId, setEmpresaId ] = useState(null)


  useEffect(()=>{
    if(empresaId){
      
    }
  },[empresaId])

  return <BaseBox>
    <NavDrawer/>
      <MainContainer maxWidth={false}>
        <MisEmpresaSearchPanel onSelect={setEmpresaId}/>
        
        {
          empresaId != null && <MeteorologiaComponent empresaId={empresaId} /> 
        }
        {
          empresaId == null && <Alert severity="warning">No hay empresa seleccionada o no tienes los privilegios para ver ninguna empresa.</Alert>
        }
        
      </MainContainer>
  </BaseBox>
}

export default MeteorologiaComponentAdmin;