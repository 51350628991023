import { useState, useContext } from "react";
import { Icon } from "@mdi/react";
import { Button, Card, CardContent, Stack, TextField, Typography, List,
  ListItem, ListItemButton, ListItemIcon,
  Checkbox, ListItemText
 } from "@mui/material";
import { postJefe } from "../../client/Jefe";
import { icons } from "../../utils/icons";
import { JefesContext } from "./JefesContext";
import { usePopup } from "../../contexts/PopupProvider";

const SensorList = ({ sensors, handleToggle, selectedSensors }) => {
  return (
    <Card sx={{ maxHeight: 400, overflow: 'auto' }}>
      <CardContent>
        <Typography variant="h6">Lista de Sensores</Typography>
        <List>
          {sensors.map((sensor) => (
            <ListItem key={sensor.id_sensor} disablePadding>
              <ListItemButton onClick={() => handleToggle(sensor.id_sensor)}>
              <ListItemIcon sx={{ minWidth: 'auto', marginRight: '0px' }}>
                  <Checkbox
                    edge="start"
                    checked={selectedSensors.indexOf(sensor.id_sensor) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${sensor.nombre} (${sensor.descripcion}) [${sensor.unidad}]`}
                  sx={{ margin: 0 }} // Remove default margin
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};
export default SensorList;
