import { useState, useEffect } from "react";
import { Stack,TextField, Button, CardContent, Grid, Box } from "@mui/material";
import { mdiContentSave } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import { putSector, deleteSectorById } from "../../client/Sector";
import KmlFileInput from "../../components/KmlFileInput";
import { calculateSphericPolygonArea } from "../../utils/SphereGeometry";
import LocalidadCard from "./LocalidadCard";
import GMapsPolygon from "../../components/GMapsPolygon";
import { usePopup } from "../../contexts/PopupProvider";
import DeleteDialogButton from "../../components/DeleteDialogButton";

const FormSector = ({ data }) => {
  const {sectores} = useLocalidades()
  const [nombre, setNombre] = useState(data.nombre)
  const [superficie, setSuperficie] = useState(data.superficie)
  const [n_plantas, setNPlantas] = useState(data.n_plantas)
  const [polygon,setPolygon] = useState(data.polygon)
  const popup = usePopup()

  const handleSaveClick = () => {
    putSector( data.sector_id, {
      nombre,
      superficie,
      n_plantas, 
      polygon,
    })
      .then(data=>{
        sectores.upsert(data);
        popup.success(`El sector ${nombre} se ha guardado correctamente`);
      })
      .catch(err=>{
        popup.error("No se pudo guardar el sector")
      })
  }

  useEffect(()=>{
    setSuperficie(calculateSphericPolygonArea(polygon))
  },[polygon])

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const handleDelete = () => {
    deleteSectorById(data.sector_id)
    .then(()=>{
      sectores.drop({sector_id: data.sector_id})
      popup.success(`El sector "${nombre}" se ha eliminado correctamente.`);
      setDeleteDialogOpen(false)
    })
    .catch(err=>{
      popup.error("No se pudo eliminar el sector")
      setDeleteDialogOpen(false)
    })
  }

  return <>
    <LocalidadCard node={{ ...data, type:"sector"}}>
      <Grid container  direction={{ xs: "column", sm: "row" }} spacing={2}>
        <Grid item xs={6}>
          <CardContent>
            <Stack spacing={2}>
              <TextField fullWidth label="Nombre" value={nombre} onChange={(e)=>setNombre(e.target.value)}/>
              <TextField fullWidth label="Num. Plantas" value={n_plantas} onChange={(e)=>setNPlantas(e.target.value)}/>
              <KmlFileInput onChange={setPolygon} value={polygon}/>
              <TextField fullWidth label="Superficie (m^2)" disabled={true} value={Math.round(superficie)}/>
            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={6}>
          <CardContent>
            <GMapsPolygon polygon={polygon}/>
          </CardContent>
        </Grid>
      </Grid>
    </LocalidadCard>
    <Stack direction="row" spacing={2} justifyContent="end" sx={{mt:2}}>
      <Button onClick={handleSaveClick} startIcon={<Icon path={mdiContentSave} size={1}/>} variant="contained">
        Guardar
      </Button>
      <Box flexGrow={1}/>
      <DeleteDialogButton
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        onConfirm={handleDelete}
        title="Eliminar Sector"
        buttonText="Eliminar Sector"
        question={<>
          ¿Estás seguro que deseas eliminar el sector <b>"{nombre}"</b>?
          Una vez eliminado no se podrá recuperar.
        </>}
      />
    </Stack>
  </>;
}

export default FormSector;