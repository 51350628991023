import { useState, useContext } from "react";
import { Icon } from "@mdi/react";
import { Button, Card, CardContent, Stack, TextField, Typography, List,
  ListItem, ListItemButton, ListItemIcon,
  Checkbox, ListItemText
 } from "@mui/material";
import { postJefe } from "../../client/Jefe";
import { icons } from "../../utils/icons";
import { JefesContext } from "./JefesContext";
import { usePopup } from "../../contexts/PopupProvider";
import {
  LineChart,
  Line,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";

const climateColors = [
  "#003f5c", // Deep blue (cold temperatures or low precipitation)
  "#2f4b7c", // Dark blue
  "#665191", // Purple (transitional)
  "#a05195", // Violet
  "#d45087", // Magenta (moderate temperatures)
  "#f95d6a", // Reddish-pink
  "#ff7c43", // Orange (warmer temperatures)
  "#ffa600", // Bright orange (hot temperatures or high precipitation)
  "#ffd700", // Gold (extremes)
  "#ffff00", // Yellow (very high extremes)
];



const EmpresaSensorsChart = ({ selectedSensorsData, tickFormatter, getSensorById }) => {

  const CustomTooltip = ({ active, payload, label }) => {
    // payload[0] is X
    // payload[1] is the value
    
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            border: '1px solid rgb(61, 61, 61)', // A solid border instead of dotted
            borderRadius: '8px', // Rounded corners
            padding: '12px 16px', // More padding for better spacing
            backgroundColor: '#ffffff', // White background
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
            fontFamily: 'Arial, sans-serif', // Consistent font
            color: '#333333', // Darker text for better readability
          }}
        >
          <p
            sx={{
              margin: '0 0 8px 0', // Add spacing between paragraphs
              fontSize: '14px', // Slightly smaller font size
              fontWeight: '600', // Bold text for emphasis
              color: '#ff4757', // Accent color for the label
            }}
          >
            <b>Valor</b> : {`${payload[1].value}`}
          </p>
          <p
            sx={{
              margin: '0', // Remove margin for the last paragraph
              fontSize: '12px', // Smaller font size for secondary info
              color: '#666666', // Lighter text for less emphasis
            }}
          >
            <b>Fecha : </b>{`${tickFormatter(payload[0].value)}`}
          </p>
        </div>
      );
    }
  
    return null;
  };

  return (
    <Card>
      <CardContent>
        <ResponsiveContainer width={"100%"} height={400}>
          <ScatterChart
            width={500}
            height={400}
            margin={{
              top: 2,
              right: 2,
              bottom: 2,
              left: 2,
            }}
          >
            <CartesianGrid strokeDasharray="3 3"/>

            <XAxis type="number" dataKey="x" name="X" tickFormatter={tickFormatter} />
            <YAxis type="number" dataKey="valor" name="Valor" /*unit="kg"*/ />
            <ZAxis type="number" range={[4,5]}  />

            <Tooltip cursor={{ strokeDasharray: "3 3" }} content={<CustomTooltip />} />
            <Legend />
            {
              Object.getOwnPropertyNames(selectedSensorsData)
                .map((id) => { 
                  return (<Scatter
                    key={id}
                    name={getSensorById(id).nombre + " " + getSensorById(id).descripcion}
                    data={selectedSensorsData[id] }
                    fill={climateColors[id % climateColors.length]}
                    isAnimationActive={false}
                    line
                    /*shape="cross"*/
                  />);
                })
            }
            
          </ScatterChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
export default EmpresaSensorsChart;
