import { Box, Container } from '@mui/material';
const MainContainer = ({children, maxWidth=true})=>{
  
  return (
    <Box sx={{flex:1, overflow:"auto"}}>
      {
        maxWidth ?       
        <Container sx={{pt:4, pb:4}} >{ children }</Container>
        :       
        <Container sx={{pt:4, pb:4}} maxWidth="true">{ children }</Container>
      }
    </Box>
  );
}

export default MainContainer;