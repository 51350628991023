import { Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import PropiedadRow from "./PropiedadRow"
import { usePlanillaEditor } from "./PlanillaEditorContext"
import { useEffect, useState } from "react";

const PlanillaSchema = ()=>{
  const { schema, consolidada, planilla } = usePlanillaEditor();

  return <>
    <CardContent>
      <Typography variant="overline">
        Parámetros
      </Typography>
    </CardContent>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{width: "25%"}}><b>Nombre</b></TableCell>
            <TableCell sx={{width:'200px'}}><b>Tipo</b></TableCell>
            <TableCell sx={{width:"500px",textAlign:"center"}}><b>Admite nulo</b></TableCell>
            <TableCell sx={{width: "50%"}}><b>Configuración adicional</b></TableCell>
            {
              !consolidada && <TableCell sx={{width: "0"}}/>
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            schema.state.map((prop,index) => (
              <PropiedadRow 
                key={ index }
                propKey={ index }
                value={ prop }
                onChange={ value => schema.upsertStrict(value) }
                onDeleteClick={ () => schema.drop(prop) }
                disabled={ consolidada }
              />
            ))
          }
          {
            !consolidada &&

            <PropiedadRow 
              key={(schema.state?.length) ?? 0 }
              propKey={(schema.state?.length) ?? 0}
              onAddClick={ (value) => schema.upsertStrict(value)}
            />
          }
        </TableBody>
      </Table>
    </TableContainer>
  </>
}

export default PlanillaSchema;