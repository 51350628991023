import { useState, useEffect } from "react";
import {
  Stack,
  TextField,
  Button,
  CardContent,
  Grid,
  Box,
} from "@mui/material";
import { mdiContentSave } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import { deleteCuartelById, putCuartel } from "../../client/Cuartel";
import KmlFileInput from "../../components/KmlFileInput";
import { calculateSphericPolygonArea } from "../../utils/SphereGeometry";
import LocalidadCard from "./LocalidadCard";
import GMapsPolygon from "../../components/GMapsPolygon";
import { usePopup } from "../../contexts/PopupProvider";
import DeleteDialogButton from "../../components/DeleteDialogButton";

const FormCuartel = ({ data }) => {
  const { cuarteles } = useLocalidades();
  const [nombre, setNombre] = useState(data.nombre);
  const [superficie, setSuperficie] = useState(data.superficie);
  const [cod_certificacion, setCodCertificacion] = useState(
    data.cod_certificacion
  );
  const [polygon, setPolygon] = useState(data.polygon);
  const popup = usePopup();

  const handleSaveClick = () => {
    putCuartel(data.cuartel_id, {
      nombre,
      superficie,
      cod_certificacion,
      polygon,
    })
      .then((data) => {
        cuarteles.upsert(data);
        popup.success(`Cuartel "${nombre}" guardado correctamente`);
      })
      .catch((err) => {
        popup.error("No se pudo guardar el cuartel");
      });
  };

  useEffect(() => {
    setSuperficie(calculateSphericPolygonArea(polygon));
  }, [polygon]);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDelete = () => {
    deleteCuartelById(data.cuartel_id)
      .then(() => {
        cuarteles.drop({ cuartel_id: data.cuartel_id });
        popup.success(`El cuartel "${nombre}" se ha eliminado correctamente.`);
        setDeleteDialogOpen(false);
      })
      .catch((err) => {
        popup.error("No se pudo eliminar el cuartel");
        setDeleteDialogOpen(false);
      });
  };

  return (
    <>
      <LocalidadCard node={{ ...data, type: "cuartel" }}>
        <Grid container direction={{ xs: "column", sm: "row" }} spacing={2}>
          <Grid item xs={6}>
            <CardContent>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  label="Nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Código de certificación"
                  value={cod_certificacion}
                  onChange={(e) => setCodCertificacion(e.target.value)}
                />
                <KmlFileInput onChange={setPolygon} value={polygon} />
                <TextField
                  fullWidth
                  label="Superficie (m^2)"
                  value={Math.round(superficie)}
                  disabled
                />
              </Stack>
            </CardContent>
          </Grid>
          <Grid item direction={{ xs: "column", sm: "row" }} xs={6}>
            <CardContent>
              <GMapsPolygon polygon={polygon} />
            </CardContent>
          </Grid>
        </Grid>
      </LocalidadCard>
      <Stack direction="row" spacing={2} justifyContent="end" sx={{ mt: 2 }}>
        <Button
          onClick={handleSaveClick}
          startIcon={<Icon path={mdiContentSave} size={1} />}
          variant="contained"
        >
          Guardar
        </Button>
        <Box flexGrow={1} />
        <DeleteDialogButton
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          onConfirm={handleDelete}
          title="Eliminar Cuartel"
          buttonText="Eliminar Cuartel"
          question={
            <>
              ¿Estás seguro que deseas eliminar el cuartel <b>"{nombre}"</b>?
              Una vez eliminado no se podrá recuperar.
            </>
          }
        />
      </Stack>
    </>
  );
};

export default FormCuartel;
