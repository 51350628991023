import { useState, useContext } from "react";
import { Icon } from "@mdi/react";
import { Button, Card, CardContent, Stack, TextField } from "@mui/material";
import { postJefe } from "../../client/Jefe";
import { icons } from "../../utils/icons";
import { JefesContext } from "./JefesContext";
import { usePopup } from "../../contexts/PopupProvider";

const AgregarJefe = ({ empresaId }) => {
  const { jefes } = useContext(JefesContext);
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [rut, setRut] = useState("");
  const [email, setEmail] = useState("");
  const popup = usePopup();

  const handleSaveClick = (e) => {
    e.stopPropagation();

    postJefe({
      nombres,
      apellidos,
      rut,
      email,
      empresa_id: empresaId,
    })
      .then((jefe) => {
        jefes.upsert(jefe.Usuario);
      })
      .catch(() => {
        popup.error(`No se pudo agregar el jefe de campo`);
      });
  };

  return (
    <Card sx={{ mb: 1 }}>
      <CardContent>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            sx={{ flex: 1 }}
            size="small"
            disabled={!empresaId}
            label="Nombres"
            value={nombres}
            onChange={(e) => setNombres(e.target.value)}
          />
          <TextField
            sx={{ flex: 1 }}
            size="small"
            disabled={!empresaId}
            label="Apellidos"
            value={apellidos}
            onChange={(e) => setApellidos(e.target.value)}
          />
          <TextField
            sx={{ flex: 1 }}
            size="small"
            disabled={!empresaId}
            label="Rut"
            value={rut}
            onChange={(e) => setRut(e.target.value)}
          />
          <TextField
            sx={{ flex: 1 }}
            size="small"
            disabled={!empresaId}
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            variant="contained"
            disabled={!empresaId}
            startIcon={<Icon path={icons.jefe} size={1} />}
            onClick={handleSaveClick}
          >
            Añadir
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
export default AgregarJefe;
