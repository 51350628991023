

class ClipMapType 
{
  constructor(polygons, map, tileUrl) {
    this.tileSize = new window.google.maps.Size(256, 256);
    this.polygons = polygons;
    this.map = map;
    this.tileUrl = tileUrl;
  }

  getTile(coord, zoom, ownerDocument) {
    const map = this.map;
    const scale = Math.pow(2, zoom);
    if (coord.y < 0 || coord.y >= scale) return ownerDocument.createElement('div');

    const tileX = ((coord.x % scale) + scale) % scale;
    const tileY = coord.y;

    const image = new Image();
    image.src = this.tileUrl
                  .replace(/{z}/, zoom)
                  .replace(/{x}/, tileX)
                  .replace(/{y}/, tileY);

    const canvas = ownerDocument.createElement('canvas');
    canvas.width = this.tileSize.width;
    canvas.height = this.tileSize.height;
    const context = canvas.getContext('2d');

    const xdif = coord.x * this.tileSize.width;
    const ydif = coord.y * this.tileSize.height;

    const combinedPath = new Path2D();

    this.polygons.forEach(pdata => {
      const polygon = pdata.polygon;
      if (polygon == null) return;
      
      let path = null;

      polygon.forEach(point => {
        const ll = new window.google.maps.LatLng(point[1], point[0]);
        const worldPoint = map.getProjection().fromLatLngToPoint(ll); 
        const x = worldPoint.x * scale - xdif;
        const y = worldPoint.y * scale - ydif;

        if (path == null) {
          path = new Path2D();
          path.moveTo(x, y);
        } else {
          path.lineTo(x, y);
        }
      });

      path.closePath();
      combinedPath.addPath(path);
    });

    image.onload = () => {
      context.beginPath();
      context.clip(combinedPath);
      
      context.drawImage(image, 0, 0);
      context.closePath();
    };

    return canvas;
  }
}

export default ClipMapType;