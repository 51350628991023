import { useEffect, useState } from "react";
import { Alert, Card, Grid, Stack } from "@mui/material";
import EmpresaSearchPanel from "../../components/EmpresaSearchPanel";
import CultivosPanel from "./CultivosPanel";
import VariedadesPanel from "./VariedadesPanel";
import PropiedadesPanel from "./PropiedadesPanel";
import MainContainer from "../../components/MainContainer";
import BaseBox from "../../components/BaseBox";
import NavDrawer from "../../components/Drawer/NavDrawer";
import CultivosVariedadesTable from "./CultivosVariedadesTable";
import { CultivosVariedadesProvider } from "./CultivosVariedadesContext";
import { useLocalidades } from "../../contexts/LocalidadesProvider";
import EmpresaAutocomplete from "../../components/EmpresaAutocomplete";
import CampoAutocomplete from "../../components/CampoAutocomplete";
import SectorAutocomplete from "../../components/SectorAutocomplete";
import { useUsuario } from "../../contexts/UsuarioProvider";


const CultivosVariedades = () => {
  const { empresas,campos,sectores } = useLocalidades();
  const [ empresaSelected, setEmpresaSelected ] = useState(null)
  const [ campoSelected, setCampoSelected ] = useState(null)
  const [ sectorSelected, setSectorSelected ] = useState(null)
  const [ cultivoId, setCultivoId ] = useState(null)
  const [ variedad, setVariedad ] = useState(null)
  const { usuario } = useUsuario()

  useEffect(()=>{
    setCampoSelected(null)
  },[empresaSelected])

  useEffect(()=>{
    setSectorSelected(null)
  },[campoSelected])


  const isRol = rol => empresas.state.find(e => e.empresa_id === empresaSelected?.empresa_id)?.roles.includes(rol)

  const isJefe = isRol("JEFE")
  const isAdmin = isRol("ADMIN")
  const isSysAdmin = usuario?.tipo === "SYSADMIN"

  const empresasDisponibles = empresas.state
  const camposDisponibles = campos.state?.filter(c=>c.empresa_id===empresaSelected?.empresa_id)
  const sectoresDisponibles = sectores.state?.filter(s=>s.campo_id===campoSelected?.campo_id)

  return (
    <BaseBox>
      <NavDrawer/>
      <MainContainer>
        <Card sx={{mb:2, p:2}}>
          <Stack spacing={2}>
            <EmpresaAutocomplete
              label="Seleccione Empresa"
              empresas={empresas.state}
              value={empresaSelected}
              onChange={(e,v)=>{
                setEmpresaSelected(v);
              }}
            />
            {
              empresaSelected &&
              camposDisponibles.length > 0 && 
              <CampoAutocomplete
                label="Seleccione Campo"
                campos={camposDisponibles}
                value={campoSelected}
                onChange={(e,v)=>{
                  setCampoSelected(v)
                }}
              />
            }

            {
              empresaSelected &&
              camposDisponibles.length === 0 &&
              <Alert severity="warning">No hay campos disponibles para esta empresa</Alert>
            }

            {
              !empresaSelected &&
              empresasDisponibles?.length > 0 &&
              <Alert severity="info">Seleeccione una empresa para continuar</Alert>
            }

            {
              empresaSelected &&
              !campoSelected &&
              camposDisponibles?.length > 0 &&
              <Alert severity="info">Seleccione un campo para continuar</Alert>
            }

            {
              campoSelected &&
              sectoresDisponibles?.length > 0 &&
      
              <SectorAutocomplete
                label="Seleccione Sector"
                sectores={sectoresDisponibles}
                value={sectorSelected}
                onChange={(e,v)=>{
                  setSectorSelected(v)
                }}
                disabled={false}
              />
            }

            {
              campoSelected &&
              sectoresDisponibles?.length === 0 &&
              <Alert severity="warning">No hay sectores disponibles para este campo</Alert>
            }

            {
              campoSelected &&
              !sectorSelected &&
              sectoresDisponibles?.length > 0 &&
              <Alert severity="info">Seleccione un sector para desplegar información</Alert>
            }
          </Stack>
        </Card>
        <CultivosVariedadesProvider sectorId={sectorSelected?.sector_id}>
          {
            isAdmin
            && empresaSelected
            && campoSelected
            && sectorSelected
            && (
              <Grid container direction={{ xs: "column", sm: "row" }}  spacing={2}>
                <Grid item xs={4}>
                  <CultivosPanel sectorId={sectorSelected?.sector_id} value={cultivoId} onChange={setCultivoId}/>
                </Grid>
                <Grid item xs={4}>
                  <VariedadesPanel cultivoId={cultivoId} value={variedad} onChange={setVariedad} />
                </Grid>
                <Grid item xs={4}>
                  <PropiedadesPanel variedad={variedad} />
                </Grid>
              </Grid>
            )
          } 
          {
            isJefe
            && !isSysAdmin
            && (
              <CultivosVariedadesTable sectorId={sectorSelected?.sector_id}/>
            )
          }
        </CultivosVariedadesProvider>
      </MainContainer>
    </BaseBox>
  );
}

export default CultivosVariedades;