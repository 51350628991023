import { useEffect, useState } from "react";
import { getAuth } from "./client/Auth"
import { createBrowserRouter, useNavigate } from "react-router-dom";
import parseJwt from "./utils/parseJwt";

// Ruta Login
import Login from "./routes/Login"

// Ruta Not Found
import NotFound from "./routes/NotFound";

// Rutas de Usuario 
import Localidades from "./routes/Localidades"
import Meteorologia from "./routes/Meteorologia"
import CultivosVariedades from "./routes/CultivosVariedades"
import JefesDeCampo from "./routes/Jefes";
import Supervisores from "./routes/Supervisores";
import Categorias from "./routes/Categorias"
import Planillas from "./routes/Planillas";
import GenerarReporte from "./routes/GenerarReporte";
import Reportes from "./routes/Reportes";

// Providers
import { LocalidadesProvider } from "./contexts/LocalidadesProvider";
import { ComunasProvider } from "./contexts/ComunasProvider";
import { GoogleMapsProvider } from "./contexts/GoogleMapsProvider";
import { UsuarioProvider } from "./contexts/UsuarioProvider";
import { PipelineProvider } from "./contexts/PipelineProvider";

const ProtectedView = ({children})=>{
  const navigate = useNavigate()
  const [authorized, setAuthorized] = useState(null);


  useEffect(()=>{
    if(!localStorage.getItem("token")){
      //console.error("ProtectedView: NO TOKEN")
      setAuthorized(false)
    }
    getAuth()
      .then(({token})=>{
        const payload = parseJwt(token)
        //if(authorizedRoles.some( x => x == payload?.tipo )){
        //  setAuthorized(true)
        //  localStorage.setItem('token',token);
        //}else{
        //  return navigate("/404")
        //}
        setAuthorized(true)
        localStorage.setItem('token',token)
      })
      .catch((error)=>{
        //console.error("ProtectedView: BACKEND ERROR")
        setAuthorized(false)
      })
  },[])
  
  return (
    authorized === true ? (
      <PipelineProvider
        providers={[
          [ UsuarioProvider ],
          [ GoogleMapsProvider ],
          [ ComunasProvider ],
          [ LocalidadesProvider ]
        ]}
      >
          { children }
      </PipelineProvider>
    ) :
    authorized === false ? <NotFound/> :
    null
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login/>,
  },
  {
    path: "/empresas",
    element: <ProtectedView><Localidades/></ProtectedView>
  },
  {
    path: "/meteorologia",
    element: <ProtectedView><Meteorologia/></ProtectedView>
  },
  {
    path: "/cultivos-variedades",
    element: <ProtectedView><CultivosVariedades/></ProtectedView>
  },
  {
    path: "/jefes",
    element: <ProtectedView><JefesDeCampo/></ProtectedView>
  },
  {
    path: "/supervisores",
    element: <ProtectedView><Supervisores/></ProtectedView> 
  },
  {
    path: "/categorias",
    element: <ProtectedView><Categorias/></ProtectedView>
  },
  {
    path: "/planillas",
    element: <ProtectedView><Planillas/></ProtectedView> 
  },
  {
    path: "/generar-reporte",
    element: <ProtectedView><GenerarReporte/></ProtectedView>
  },
  {
    path: "/reportes",
    element: <ProtectedView><Reportes/></ProtectedView>
  },
  {
    path: "*",
    element: <NotFound/>
  }
]);

export default router;